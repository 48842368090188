import React from 'react'
import styled, { css } from 'styled-components'

import Container from 'components/shared/container'
import { Text } from 'components/shared/typography'
import useBreakpoint from 'hooks/useBreakpoint'

type Props = {
  label: string
  centered?: boolean
}

const Wrapper = styled.div`
  width: 100%;
  padding: 20px 0;
  background-color: ${({ theme }) => theme.colors.gray100};

  ${({ theme }) => theme.media.lg.min} {
    padding: 0;
    background-color: ${({ theme }) => theme.colors.white};
  }
`

const LocationInfo = styled.div<{ centered?: boolean }>`
  display: flex;
  align-items: center;
  width: fit-content;
  height: 40px;
  padding-inline: 15px;
  background-color: ${({ theme }) => theme.colors.primary100};

  ${({ theme }) => theme.media.lg.min} {
    margin: 20px 0 20px 30px;
    padding-inline: 25px;
    border-radius: 5px;

    ${({ centered }) =>
      centered &&
      css`
        margin: 20px auto;
      `}
  }
`

const Location: React.FC<Props> = ({ label, centered }) => {
  const { lg } = useBreakpoint()

  return (
    <Wrapper>
      <Container>
        <LocationInfo centered={centered}>
          <Text
            size={lg ? 18 : 16}
            weight={500}
            themecolor="white"
            margin="0"
            align="center"
            dangerouslySetInnerHTML={{ __html: label }}
          />
        </LocationInfo>
      </Container>
    </Wrapper>
  )
}

export default Location
