import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/shared/SEO'

import Hero from 'components/layout/Sections/Hero'
import Location from 'components/layout/Posts/Location'
import Tiles from 'components/layout/Posts/PostsTiles2'
import Navigation from 'components/layout/Navigation/index'

import ZoomedModalImg from 'components/shared/ZoomedModalImg'

const breadcrumbs = {
  mobile: [
    {
      label: 'Inspiracje',
      link: '/inspiracje/',
    },
    {
      label: 'Produkty',
    },
  ],
  desktop: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Inspiracje',
      link: '/inspiracje/',
    },
    {
      label: 'Produkty',
    },
  ],
}

const ProduktyPage: React.FC<PageProps> = ({ data }) => {
  const PAGE = data?.wpPage?.produkty
  const PAGE_SEO = data?.wpPage?.seo

  const [zoomedItemNumber, setZoomedItemNumber] = React.useState(0)

  const handleZoom = (itemNumber: number) => {
    setZoomedItemNumber(itemNumber)
  }

  const PRODUCTS = PAGE?.productsSingle
    .reduce((prev, current, index) => {
      const item1 = {
        img: current?.productsSingleImg1?.localFile?.childImageSharp
          ?.gatsbyImageData,
        alt: current?.productsSingleImg1?.altText,
        badge1Text: current?.productsSingleTitle1,
      }

      const item2 = {
        img: current?.productsSingleImg2?.localFile?.childImageSharp
          ?.gatsbyImageData,
        alt: current?.productsSingleImg2?.altText,
        badge1Text: current?.productsSingleTitle2,
      }

      const item3 = {
        img: current?.productsSingleImg3?.localFile?.childImageSharp
          ?.gatsbyImageData,
        alt: current?.productsSingleImg3?.altText,
        badge1Text: current?.productsSingleTitle3,
      }

      const item4 = {
        img: current?.productsSingleImg4?.localFile?.childImageSharp
          ?.gatsbyImageData,
        alt: current?.productsSingleImg4?.altText,
        badge1Text: current?.productsSingleTitle4,
      }

      return index !== 1
        ? [...prev, item1, item2, item3, item4]
        : [
            {
              img: prev?.productsSingleImg1?.localFile?.childImageSharp
                ?.gatsbyImageData,
              alt: current?.productsSingleImg1?.altText,
              badge1Text: prev?.productsSingleTitle1,
            },
            {
              img: prev?.productsSingleImg2?.localFile?.childImageSharp
                ?.gatsbyImageData,
              alt: current?.productsSingleImg2?.altText,
              badge1Text: prev?.productsSingleTitle2,
            },
            {
              img: prev?.productsSingleImg3?.localFile?.childImageSharp
                ?.gatsbyImageData,
              alt: current?.productsSingleImg3?.altText,
              badge1Text: prev?.productsSingleTitle3,
            },
            {
              img: prev?.productsSingleImg4?.localFile?.childImageSharp
                ?.gatsbyImageData,
              alt: current?.productsSingleImg4?.altText,
              badge1Text: prev?.productsSingleTitle4,
            },
          ]
    })
    .map((item, index) => ({
      ...item,
      itemNumber: index,
    }))

  return (
    <Layout>
      <SEO
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Navigation />
      <Hero
        imgMobile={
          PAGE?.productBannerImg?.localFile?.childImageSharp?.gatsbyImageData
        }
        imgDesktop={
          PAGE?.productBannerImg?.localFile?.childImageSharp?.gatsbyImageData
        }
        headingMobile="Produkty"
        headingDesktop="Produkty"
        breadcrumbs={breadcrumbs}
      />
      <Location label="Produkty" centered />
      <Tiles heading="Produkty" gallery={PRODUCTS} onZoom={handleZoom} />
      <ZoomedModalImg img={PRODUCTS[zoomedItemNumber].img} alt="product" />
    </Layout>
  )
}

export default ProduktyPage

export const query = graphql`
  query ProduktyPage {
    heroImgMobile: file(name: { eq: "placeholder-img-black" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          placeholder: DOMINANT_COLOR
          formats: [AUTO, WEBP]
        )
      }
    }
    wpPage(slug: { eq: "produkty" }) {
      seo {
        title
        opengraphDescription
        opengraphAuthor
        metaRobotsNoindex
        metaRobotsNofollow
        metaKeywords
        metaDesc
      }
      produkty {
        productsBannerTitle
        productBannerImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        productsSingle {
          productsSingleTitle1
          productsSingleTitle2
          productsSingleTitle3
          productsSingleTitle4
          productsSingleImg1 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1400
                  placeholder: BLURRED
                  formats: [JPG, AUTO]
                )
              }
            }
          }
          productsSingleImg2 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1400
                  placeholder: BLURRED
                  formats: [JPG, AUTO]
                )
              }
            }
          }
          productsSingleImg3 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1400
                  placeholder: BLURRED
                  formats: [JPG, AUTO]
                )
              }
            }
          }
          productsSingleImg4 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1400
                  placeholder: BLURRED
                  formats: [JPG, AUTO]
                )
              }
            }
          }
        }
      }
    }
  }
`
