import React, { useEffect, useState, useRef, useContext } from 'react'
import styled, { css } from 'styled-components'

import LazyImage from 'components/shared/lazyImage'
import Icon from 'components/shared/icon'
import { ImageDataLike } from 'gatsby-plugin-image'

import closeIcon from 'assets/icons/close.svg'

import useBreakpoint from 'hooks/useBreakpoint'
import ModalContext from 'contexts/modalContext'

type Props = {
  img: ImageDataLike
  alt: string
}

const Overlay = styled.div<{ isVisible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 200;
  background: rgba(0, 0, 0, 0.725);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  .swiper {
    width: 100%;
    height: 100%;
    .swiper-wrapper {
      width: 100%;
      height: 100%;
      .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%;

  .gatsby-image-wrapper {
    max-height: 100%;
  }
`

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  cursor: pointer;
  transition: 200ms ease-in-out;
`

const ZoomedModalImg: React.FC<Props> = ({ img, alt }) => {
  const { modalVisible, closeModal } = useContext(ModalContext)

  useEffect(() => {
    const handleKeyboardNavigation = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeModal()
      }
    }

    window.addEventListener('keydown', handleKeyboardNavigation)
    return () => window.removeEventListener('keydown', handleKeyboardNavigation)
  }, [])

  const { xl } = useBreakpoint()

  return (
    <Overlay isVisible={modalVisible}>
      <InnerWrapper>
        <LazyImage src={img} alt={alt} objectFit="contain" />
      </InnerWrapper>
      <CloseButton type="button" onClick={closeModal} aria-label="close">
        <Icon src={closeIcon} alt="" size={xl ? 24 : 18} />
      </CloseButton>
    </Overlay>
  )
}

export default ZoomedModalImg
